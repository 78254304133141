<template>
  <el-form ref="form" :model="distributeStoreForm" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton :isImportOrderBtn="showImportBill" :isAuditBillBtn="false" :isAddBtn="showAdd"
        :disabled="disabled" @submitForm="submitForm" @addBill="addBill" @handleAdd="handleAdd" @getQuit="getQuit"
        @getImportOrder="getImportOrder" id="topOperatingButton">
        <template #specialDiyBtn>
          <el-button type="success" @click="auditBill('分货')" :disabled="showAudit" size="mini">分货
          </el-button>
          <el-button @click="handleExport" :disabled="!distributeStoreForm.billId" size="mini" type="primary">导出
          </el-button>
          <NowPrintButton dialogType='PrintTemplateNowPrint' :billId="distributeStoreForm.billId"
            :filters="nowPrintOptions" style="margin: 0 10px" />
          <NowPrintButton dialogType='PrintTemplateNowPrintShop' :billId="distributeStoreForm.billId"
            :filters="nowPrintOptions" style="margin-right:10px" btnText='分店打印' />
        </template>
      </topOperatingButton>
      <!-- 基本信息盒子 -->
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="基本信息" id="basicInformation" :isCargoOrder="true"
        :billStatus="distributeStoreForm.billStatus">
        <template slot="cardContent">
          <!-- 商品信息 -->
          <div class="x-w marT10">
            <!-- 第一行 -->
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="distributeStoreForm.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" size="mini" v-model="distributeStoreForm.billDate" type="date"
                placeholder="单据日期" :disabled="distributeStoreForm.billStatus == '2'" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input class="inputWidth" size="mini" :disabled="distributeStoreForm.billStatus == '2'"
                v-model="distributeStoreForm.handBillNo" placeholder="手工单据号"></el-input>
            </el-form-item>
            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="distributeStoreForm.produceDeptId"
                style="width: 250px" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled:
                          disabled ||
                          isImportSource ||
                          distributeStoreForm.billStatus == '2',
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input size="mini" class="inputRemark" :disabled="distributeStoreForm.billStatus == '2'"
                v-model="distributeStoreForm.billRemark" type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="40"
                :autosize="{ minRows: 1, maxRows: 5 }" show-word-limit />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->

      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="rightCardTitle">
          <div class="marR10 x-f">
            <span class="fS14MR10">分货路线</span>
            <SelectLocal v-model="distributeStoreForm.deliverLineId" @selectChange="deliverLineChange"
              style="width: 250px" :option="{
                clearable: true,
                data: deliverLineList,
                value: 'lineId',
                label: 'lineName',
              }" />
            <el-button :disabled="!distributeStoreForm.produceDeptId" type="primary" size="mini"
              @click="getDistribution(1)" class="marL10">批量修改仓库</el-button>
            <el-button :disabled="disabled" type="primary" size="mini" @click="getDistribution(2)">分货对象</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <!-- show-footer
              :footer-method="footerMethod" -->
          <!-- 商品信息按钮和表格整体盒子  -->
          <div class="goodsMessage">
            <vxe-table border show-overflow show-header-overflow ref="multipleTable"
              :data="distributeStoreForm.detailItem" @checkbox-change="handleSelectionChange"
              @checkbox-all="handleSelectionChange" :loading="loadingTable" max-height="600px" height="600px"
              :checkbox-config="{ trigger: 'row' }" :row-config="{ isHover: true, height: 60 }"
              :sort-config="{ trigger: 'cell' }" :scroll-y="{ enabled: true }" :column-config="{resizable: true}">
              <vxe-column fixed="left" type="checkbox" width="60" :selectable="disableCheckbox" align="center" />
              <vxe-column align="center" :title="tableCellLabel" width="80" fixed="left">
                <template v-slot="scope">
                  <div @mouseenter="cellMouseEnter(scope.row)" @mouseleave="cellMouseLeave(scope.row)">
                    <i v-show="scope.row.hoverRow" :disabled="disabled"
                      @click.stop="row('push', scope.rowIndex,scope.row)" class="el-icon-circle-plus operatePush"></i>
                    <i v-show="scope.row.hoverRow" :disabled="disabled"
                      @click.stop="row('del', scope.rowIndex,scope.row)" class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.rowIndex + 1 }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <vxe-column fixed="left" title="商品编码" width="180" align="center" field="goodsNo">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>商品编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.goodsNo'"
                    :rules="rules[`detailItem.goodsNo`]">
                    <SelectRemote :disabled="scope.row.isDelete == 0" v-model="scope.row.goodsNo"
                      :option="optionGoods(scope.row)" :ref="'goodsNo'+'_'+scope.rowIndex +'_1'"
                      @handleSelectKeydown="handleTableKeydown($event, scope.row,'goodsNo',scope.rowIndex,1)" />
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column fixed="left" title="商品名称" align="center" field="goodsName" width="170" />
              <vxe-column title="商品条码" align="center" field="barcode" width="170" />
              <vxe-column title="规格" align="center" field="goodsSpec" width="150" />
              <vxe-column title="调出仓库" align="center" field="outStoreId" min-width="180">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>调出仓库</span>
                </template>
                <template scope="scope">
                  <el-form-item :prop="'detailItem.' + scope.rowIndex + '.outStoreId'"
                    :rules="rules[`detailItem.outStoreId`]">
                    <SelectRemote @selectChange="(v, row) => changeStore(scope.row, row)" v-model="scope.row.outStoreId"
                      style="width: 150px" :disabled="
                        scope.row.isDelete == 0 ||
                        !distributeStoreForm.produceDeptId
                      " :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId:
                                  distributeStoreForm.produceDeptId,
                              },
                              disabled: disabled,
                              showItem: [
                                {
                                  storeId: scope.row.outStoreId,
                                  produceStoreName: scope.row.outStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " :ref="'outStoreId'+'_'+scope.rowIndex +'_5'"
                      @handleSelectKeydown="handleTableKeydown($event, scope.row,'outStoreId',scope.rowIndex,5)" />
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="单位" align="center" field="unitName" width="100" />
              <!-- 采用 v-for 实现动态列 -->

              <vxe-colgroup v-for="(item, index) in distributeStoreForm.detailItem[0]
                  .storeItemCache" :title="item.orderStoreName" align="center" :key="`${item.uid}.${index}`">
                <vxe-column title="订货数" align="center" :field="`orderQty.${item.orderStoreId}`" width="80">
                  <template scope="scope">
                    <span>{{
                      scope.row.storeItemCache[index]
                        ? scope.row.storeItemCache[index].orderQty
                        : ""
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column title="已分货数" align="center" :field="`alreadyDistributeQty.${item.orderStoreId}`" width="80">
                  <template scope="scope">
                    <span>{{
                      scope.row.storeItemCache[index]
                        ? scope.row.storeItemCache[index].alreadyDistributeQty
                        : ""
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column title="分货数" align="center" width="100" :field="`distributeQty.${item.orderStoreId}`">
                  <template scope="scope">
                    <el-form-item :prop="
                        'detailItem.' +
                        scope.rowIndex +
                        '.storeItemCache.' +
                        index +
                        '.distributeQty'
                      " :rules="rules[`storeItem.storeItemCache`]">
                      <!-- 这里实现复选框的绑定 -->
                      <!-- <div>{{ scope.row.storeItemCache[index].uid }}</div> -->
                      <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent @click.native.stop size="mini"
                        type="number" @keyup.native="onkeyupDistributeQty(scope.rowIndex)"
                        v-model="scope.row.storeItemCache[index].distributeQty" maxlength="7" @input="
                          limitInputlength(
                            scope.row.storeItemCache[index].distributeQty,
                            scope.row.storeItemCache[index],
                            'distributeQty',
                            true
                          )
                        " :ref="'distributeQty'+'_'+scope.rowIndex +'_'+dividedNumberTable[index].columnindex"
                        @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'distributeQty',scope.rowIndex,dividedNumberTable[index].columnindex)"
                        @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'distributeQty',scope.rowIndex,dividedNumberTable[index].columnindex)"
                        @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'distributeQty',scope.rowIndex,dividedNumberTable[index].columnindex)"
                        @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'distributeQty',scope.rowIndex,dividedNumberTable[index].columnindex)"
                        @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'distributeQty',scope.rowIndex,dividedNumberTable[index].columnindex)"></el-input>
                    </el-form-item>
                  </template>
                </vxe-column>
              </vxe-colgroup>
              <vxe-column title="订货总数" align="center" field="orderTotalQty" width="80" />
              <vxe-column title="完工总数" align="center" field="sourceQty" width="80" />
              <vxe-column title="库存总数" align="center" field="storeQty" width="80" />
              <vxe-column title="已分货总数" align="center" field="alreadyDistributeTotalQty" width="100" />
              <vxe-column title="分货总数" align="center" field="distributeTotalQty" width="80" />
              <vxe-column title="备注" align="center" field="remark" min-width="130">
                <template slot-scope="scope">
                  <el-input @click.native.stop size="mini" v-model="scope.row.remark"
                    :disabled="scope.row.isDelete == 0" maxlength="80"
                    :ref="'remark'+'_'+scope.rowIndex +'_'+canKeyTableColumnList[canKeyTableColumnList.length-1].columnindex"
                    @keydown.native="handleTableKeydown($event,scope.row, 'remark',scope.rowIndex,canKeyTableColumnList[canKeyTableColumnList.length-1].columnindex)"></el-input>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getNewDate } from "@/utils/newDate"; //单据日期
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  storeDistributeAddAPI,
  storeDistributeDetailAPI,
  storeDistributeUpdateAPI,
  storeDistributeUpdateStatusAPI,
  findStockQtyAPI,
  sortGoods,
  getSourceOrderDetailData
} from "@/api/produce/bill/storeDistribute"; //产品分货单
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from "@/components/Dialog";
import { limitInputlength, fcount, isNumber } from "@/utils";
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceListAPI } from "@/api/system/maintain/param/produceParam"; //业务参数的重复分货控制
import {
  queryDeliverLineList,
  getAllStoreList,
} from "@/api/system/partner/deliverLine"; //配送路线
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import { deepCopy, getUid } from "@/utils/index.js";
import tableKeydown from "@/utils/mixin/tableKeydown";
export default {
  name: "storeDistributeDetail",
  mixins: [tableKeydown],
  components: {
    NowPrintButton: () =>
      import("@/components/tablePage/tableButtons/nowPrint.vue"),
    cardTitleCom,
    Dialog,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
  },
  data() {
    return {
      test: {},
      isImportSource: false, //是否引入源单
      deliverLineList: [], //配送路线
      isDistribution: "Y", //重复分货
      tableCellLabel: "序号",
      //弹窗配置
      dialogOptions: {
        title: "选择仓库",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      //原料、半成品、成品id
      goodsStoreObj: {},
      //表单禁用
      disabled: false,
      showAudit: true, //审核
      //表格遮罩
      loadingTable: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      //调出仓库id
      ids: [],
      //调出仓库多选
      multiple: true,
      // 表单参数
      distributeStoreForm: {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        detailItem: [
          {
            // 仓库数组信息
            storeItem: [],
            storeItemCache: [],
          },
        ],
      },

      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "生产工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "detailItem.outStoreId": [
          {
            required: true,
            message: "调出仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "storeItemCache.distributeQty": [
          {
            required: true,
            message: "分货数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
      dividedNumberTable: [],
      canKeyTableColumnList: [
        { key: 'goodsNo', columnindex: 1, componentRefName: 'remoteSelect' },
        { key: 'outStoreId', columnindex: 5, componentRefName: 'remoteSelect' },
        { key: 'remark', columnindex: 11 }
      ],//可以键盘导航的表格列详情
    };
  },
  // updated () {
  //   this.$nextTick(() => {
  //     this.$refs.multipleTable.doLayout()
  //   })
  // },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      //初始化数据
      await vm.getInitializeData();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        console.log("详情", deepCopy(vm.distributeStoreForm));
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await storeDistributeDetailAPI(
            vm.$route.query.billId
          );
          //单据赋值
          vm.distributeStoreForm = data;
          vm.deliverLineChange();
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.distributeStoreForm));
        }
      } else if (vm.$route.query.type === "distributeImport") {
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.distributeStoreForm = vm.oldForm;
        }
        try {
          if (
            vm.$route.query.sourceIds
          ) {
            const sourceIds = JSON.parse(vm.$route.query.sourceIds);
            vm.isImportSource = true;
            //缓存工厂
            vm.distributeStoreForm.produceDeptId = Number(
              vm.$route.query.produceDeptId
            );
            const { data } = await getSourceOrderDetailData(sourceIds);
            const sourceGoodsList = data.detailItem.map(v => {
              v.lineType = 1;
              v.inUnitQty = v.unitAlreadyInQty;
              //分货数=订货数
              if (v.storeItem && v.storeItem.length > 0) {
                v.storeItem.forEach((item) => {
                  item.distributeQty = item.orderQty
                });
              }
              return v;
            });
            vm.distributeStoreForm.importDetailItem = data.importDetailItem;
            vm.distributeStoreForm.detailItem = sourceGoodsList.map((v) => {
                //分货数=订货数
                if (v.storeItem && v.storeItem.length > 0) {
                  v.storeItem.forEach((item) => {
                    vm.$set(item, "distributeQty", item.orderQty);
                  });
                }
                return v;
              });

            if (vm.distributeStoreForm.detailItem.length == 0) {
              vm.distributeStoreForm.detailItem = [{}];
            }
            await vm.deliverLineChange();
            vm.oldForm = JSON.parse(JSON.stringify(vm.distributeStoreForm));
          } else {
            //判断是否有以前新增时保存的数据
            if (JSON.stringify(vm.oldForm) != "{}") {
              vm.distributeStoreForm = vm.oldForm;
            }
            vm.oldForm = JSON.parse(JSON.stringify(vm.distributeStoreForm));
          }
        } catch (error) {
          //判断是否有以前新增时保存的数据
          if (JSON.stringify(vm.oldForm) != "{}") {
            vm.distributeStoreForm = vm.oldForm;
          }
          vm.oldForm = JSON.parse(JSON.stringify(vm.distributeStoreForm));
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.distributeStoreForm = vm.oldForm;
        }
        await vm.getOrderBillNo();
        vm.oldForm = JSON.parse(JSON.stringify(vm.distributeStoreForm));
        console.log("新增", deepCopy(vm.distributeStoreForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.distributeStoreForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.distributeStoreForm));
    }
    next();
  },
  beforeDestroy() {
    this.oldForm = {};
  },
  watch: {
    //侦听单据状态
    "distributeStoreForm.billStatus": {
      handler(newVal) {
        if (this.distributeStoreForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;

          //打开审核按钮
          this.showAudit = false;
          if (this.isDistribution == "N") {
            //禁用输入按钮、保存按钮
            this.disabled = true;
            //打开审核按钮
            this.showAudit = true;
          }
        } else if (this.distributeStoreForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.distributeStoreForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.distributeStoreForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    "distributeStoreForm.detailItem": {
      handler(val) {
        if (val) {
          let storeItemCache = val[0].storeItemCache || [];
          this.dividedNumberTable = [];
          storeItemCache.forEach((item, index) => {
            this.dividedNumberTable.push({
              key: 'distributeQty', columnindex: 9 + index * 3
            })
          })
          if (this.dividedNumberTable.length > 0) {
            this.canKeyTableColumnList = [
              { key: 'goodsNo', columnindex: 1, componentRefName: 'remoteSelect' },
              { key: 'outStoreId', columnindex: 5, componentRefName: 'remoteSelect' },
              { key: 'remark', columnindex: 11 }
            ]
            this.canKeyTableColumnList.splice(2, 0, ...this.dividedNumberTable);
            this.canKeyTableColumnList[this.canKeyTableColumnList.length - 1].columnindex
              = this.dividedNumberTable[this.dividedNumberTable.length - 1].columnindex + 6;
          }
        }
        this.hasTableKey = val;
        this.$nextTick(() => {
          this.$refs.multipleTable.updateFooter();
        });
      },
      deep: true,
    },
  },
  computed: {
    nowPrintOptions() {
      let lineName = "";
      const findName = this.deliverLineList?.find?.(
        (x) => x.lineId === this.distributeStoreForm.deliverLineId
      );
      if (findName?.lineName) {
        lineName = findName.lineName;
      }
      return {
        detail: JSON.parse(
          JSON.stringify({
            ...this.distributeStoreForm,
            detailItem:
              this.distributeStoreForm.detailItem?.map?.((x) => ({
                ...x,
                storeItem: x.storeItemCache,
              })) || [],
          })
        ),
        topRightText: `路线：${lineName}`,
        topRightBillNo: `单据编号：${this.distributeStoreForm.billNo}`,
      };
    },
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            // forbidden:
            //   this.distributeStoreForm.detailItem[0].storeItem.length <= 0,
            // forbiddenTitle: '请先选择仓库',
            billDetailItems: this.distributeStoreForm.detailItem,
            getMultipleGoods: this.getGoodS,
            row,
          },
          that
        );
      };
    },
  },
  methods: {
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/produce/bill/goods/distribute/exportDetails',
        {
          billId: this.distributeStoreForm.billId
        },
        `产品分货导出.xlsx`
      )
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      this.tableCellLabel = "操作";
      let isbo = this.distributeStoreForm.detailItem.some((v) => v.hoverRow);
      if (isbo) {
        this.distributeStoreForm.detailItem.forEach((v) => {
          if (v.hoverRow) {
            v.hoverRow = false;
          }
        });
      }
      this.$set(row, "hoverRow", true);
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      this.tableCellLabel = "序号";
      // 移除hover的事件
      this.$set(row, "hoverRow", false);
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.distributeStoreForm.detailItem.forEach((item) => {
        item.outStoreId = undefined;
        item.storeQty = 0;
      });
    },
    //分货路线
    deliverLineChange() {
      this.distributeStoreForm.detailItem.forEach((item, i) => {
        item.storeItemCache = item.storeItem.filter(
          (v) =>
            this.distributeStoreForm.deliverLineId == v.deliverLineId ||
            !this.distributeStoreForm.deliverLineId
        );
        this.onkeyupDistributeQty(i);
      });
    },
    disableCheckbox(row) {
      return row.isDelete != 0;
    },
    //打开弹窗
    getDistribution(val) {
      if (val == 1 && this.multiple) return this.$message.warning("请选择商品");
      this.distributionIndex = val;
      if (val == 1) {
        this.dialogOptions.title = "选择仓库";
        this.dialogOptions.formData = this.$dialog({
          key: "produceStore",
          option: {
            table: {
              ...this.$dialog({ key: "produceStore" }).table,
              radioSelect: true,
              mutiSelect: false,
            },
          },
        });
        this.dialogOptions.formData.table.pagination = {
          ...this.dialogOptions.formData.table.pagination,
          produceDeptId: this.distributeStoreForm.produceDeptId,
        };
      } else if (val == 2) {
        this.dialogOptions.title = "选择仓库";
        this.dialogOptions.formData = this.$dialog({
          key: "store",
          option: {
            queryParams: {
              deliverLineId:
                val == 2 ? this.distributeStoreForm.deliverLineId : "",
            },
            table: {
              ...this.$dialog({ key: "store" }).table,
              list: getAllStoreList,
              radioSelect: val == 1 ? true : false,
              mutiSelect: val == 1 ? false : true,
            },
          },
        });
        this.dialogOptions.formData.table.echoChecked =
          this.distributeStoreForm.detailItem[0].storeItem?.map(
            (item) => item.orderStoreId
          );
        this.dialogOptions.formData.table.pagination = {
          ...this.dialogOptions.formData.table.pagination,
          pageSize: 100,
        };
      }
      this.dialogOptions.show = true;
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (this.distributionIndex == 1) {
            this.chooseOutStoreIdChange(
              this.dialogOptions.formData.table.check[0].storeId,
              this.dialogOptions.formData.table.check[0].produceStoreName
            );
          } else if (this.distributionIndex == 2) {
            //仓库
            this.storeSelectionList(
              this.dialogOptions.formData.table.check,
              "storeId",
              "storeName",
              "1"
            );
            //分货类型(1:分给门店;2:分给客户;)
          }

          break;
        default:
          break;
      }
    },
    footerMethod({ columns, data }) {
      // 返回一个二维数组的表尾合计 updateFooter
      // console.log('XEUtils', XEUtils.mean(data, 1111))
      console.log(
        "columns",
        columns.map((x) => x.property)
      );
      // console.log('data', data)

      let ol = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (
            [
              "orderQty",
              "alreadyDistributeQty",
              "distributeQty",
              "orderTotalQty",
              "sourceQty",
              "storeQty",
              "distributeTotalQty",
            ].includes(column.property) ||
            column.property?.indexOf?.(".") >= 0
          ) {
            if (column.property.indexOf(".") >= 0) {
              const [key, orderStoreId] = column.property.split(".");
              return data
                .map(
                  (x) =>
                    x.storeItemCache?.find?.(
                      (y) => y.orderStoreId === orderStoreId
                    )?.[key] || 0
                )
                .reduce(
                  (p, c) =>
                    fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
                  0
                );
            }
            return data
              .map((x) => x[column.property])
              .reduce(
                (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
                0
              );
            // this.sumNum(data, column.property)
          }
          return "";
        }),
      ];
      console.log("ol", ol);
      return ol;
    },
    sumNum(list, field) {
      let count = 0;
      list.forEach((item) => {
        count += Number(item[field]);
      });

      return count;
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = [
          "orderQty",
          "alreadyDistributeQty",
          "distributeQty",
          "orderTotalQty",
          "sourceQty",
          "storeQty",
          "distributeTotalQty",
        ];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.(
                  (x) => x.orderStoreId === orderStoreId
                )?.[key] || 0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    //切换调出仓库
    async chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      try {
        this.loadingTable = true;
        const stockQtys = await findStockQtyAPI({
          goodsIds: this.ids,
          storeIds: [chooseOutStoreId],
        });
        console.log("stockQtys", stockQtys);
        // this.$set(item, 'storeQty', stockQtys.data[0].stockQty)

        this.distributeStoreForm.detailItem.forEach(async (item) => {
          if (this.ids.includes(item.goodsId) && item.isDelete !== 0) {
            const obj = stockQtys?.data?.find((x) => x.goodsId == item.goodsId);
            this.$set(item, "outStoreId", chooseOutStoreId);
            this.$set(item, "outStoreName", produceStoreName);
            this.$set(item, "storeQty", obj?.stockQty);
          }
        });
        // Promise.all().then(() => {
        //   this.loadingTable = false
        // })
        this.loadingTable = false;
      } catch (error) {
        this.loadingTable = false;
      }
    },
    // 多选框选中数据
    handleSelectionChange() {
      const selection = this.$refs.multipleTable.getCheckboxRecords();
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    //切换仓库
    async changeStore(row, obj) {
      //查库存
      let stockQtys = await findStockQtyAPI({
        goodsIds: [row.goodsId],
        storeIds: [row.outStoreId],
      });

      this.$set(
        row,
        "storeQty",
        stockQtys?.data ? stockQtys?.data[0]?.stockQty : 0
      );

      this.$set(row, "outStoreName", obj.produceStoreName);
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.oldForm = JSON.parse(JSON.stringify(this.distributeStoreForm));
      this.loading = false;
    },
    //分货
    onkeyupDistributeQty(index) {
      if (!this.distributeStoreForm.detailItem[index]) return;
      let distributeTotalQty = this.distributeStoreForm.detailItem[
        index
      ]?.storeItemCache.reduce((total, v) => {
        return total + Number(v.distributeQty || 0);
      }, 0);
      this.$set(this.distributeStoreForm.detailItem[index], "distributeTotalQty", this.formatNumber(distributeTotalQty))
    },
    //退出
    getQuit() {
      // 重置数据
      this.reset();
      this.oldForm = {};
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/produce/bill/storeDistribute" });
    },
    //单据编号
    async getOrderBillNo() {
      this.distributeStoreForm.billNo = await getBillNo(170204);
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        //发送审核api
        const { data } = await sortGoods(this.distributeStoreForm);
        this.distributeStoreForm = data;
        this.deliverLineChange();
        // 弹出提示
        this.$message.success("分货成功");
      } catch { }
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      if (
        this.isDistribution == "N" &&
        this.distributeStoreForm.billStatus == "2"
      ) {
        this.$message.error("保存失败，不能重复分货");
        return true;
      }
      if (this.distributeStoreForm.detailItem[0].storeItem?.length <= 0) {
        return this.$message({
          message: "分货对象不能为空",
          type: "warning",
        });
      }
      try {
        this.loading = true;
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            if (this.distributeStoreForm.billId) {
              const res = await storeDistributeUpdateAPI(
                this.distributeStoreForm
              );
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.distributeStoreForm = res.data;
                this.deliverLineChange();

                this.$modal.msgSuccess("修改单据成功");
              } else {
                await this.reset();
                await this.getOrderBillNo();
                this.$modal.msgSuccess("保存并新增单据成功");
              }
            } else {
              const { data } = await storeDistributeAddAPI(
                this.distributeStoreForm
              );
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.distributeStoreForm = data;
                this.deliverLineChange();
                this.$modal.msgSuccess("新增单据成功");
              } else {
                await this.reset();
                await this.getOrderBillNo();
                this.$modal.msgSuccess("保存并修改单据成功");
              }
            }
          }
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },
    //选中的仓库
    storeSelectionList(value, id, name, distributionType) {
      console.log("===", value);
      const array = value.map((v) => ({
        distributeObjId: v.distributeObjId || v[id], //分货对象id
        orderStoreId: v[id], //仓库id
        orderStoreName: v[name], //仓库名称
        deliverLineId: v.deliverLineId, //路线
        orderQty: undefined, ////订货数
        alreadyDistributeQty: undefined, //完成数
        distributeQty: 0, //分货数
        distributionType, //分货类型(1:分给仓库;2:分给客户;)
      }));
      // //去重旧元素
      this.distributeStoreForm.detailItem =
        this.distributeStoreForm.detailItem.map((x, i) => {
          const newData = x.storeItem?.filter((y) =>
          (y.distributionType == '2' || array.find(
            (v) =>
              v.distributionType == distributionType &&
              y.distributeObjId == v.distributeObjId
          ))
          );

          return {
            ...x,
            storeItem: newData,
          };
        });

      //过滤新元素
      const newElements = array.filter(
        (item) =>
          !this.distributeStoreForm.detailItem[0]?.storeItem?.some(
            (v) =>
              v.distributionType === item.distributionType &&
              v.orderStoreId === item.orderStoreId
          )
      );
      //赋值新元素
      if (newElements.length > 0) {
        this.distributeStoreForm.detailItem.forEach((item) => {
          if (!item.storeItem) {
            item.storeItem = [];
          }
          item.storeItem.push(
            ...newElements.map((x) => ({ ...x, uid: getUid() }))
          );
        });
      }

      this.distributeStoreForm.detailItem.forEach((item, i) => {
        item.storeItemCache = item.storeItem?.filter((v) => {
          if (
            this.distributeStoreForm.deliverLineId == v.deliverLineId ||
            !this.distributeStoreForm.deliverLineId
          ) {
            return v;
          }
        });
        this.onkeyupDistributeQty(i);
      });
      console.log("aaaaaaaaaa", this.distributeStoreForm.detailItem);
    },
    //获取仓库名称
    getOrderStoreArr() {
      let array = [];
      for (
        let index = 0;
        index < this.distributeStoreForm.detailItem[0].storeItem?.length;
        index++
      ) {
        array.push({
          orderStoreName:
            this.distributeStoreForm.detailItem[0].storeItem[index]
              .orderStoreName,
          orderStoreId:
            this.distributeStoreForm.detailItem[0].storeItem[index]
              .orderStoreId,
          distributionType:
            this.distributeStoreForm.detailItem[0].storeItem[index]
              .distributionType,
          deliverLineId:
            this.distributeStoreForm.detailItem[0].storeItem[index]
              .deliverLineId,
          distributeObjId:
            this.distributeStoreForm.detailItem[0].storeItem[index]
              .distributeObjId,
          orderQty: undefined,
          alreadyDistributeQty: undefined,
          distributeQty: 0,
        });
      }
      return array;
    },
    //表格增加/减少一行方法
    row(name, index, row) {
      if (name === "push") {
        let array = this.getOrderStoreArr();
        array = JSON.parse(JSON.stringify(array));
        this.distributeStoreForm.detailItem.splice(index + 1, 0, {
          storeItem: array,
          storeItemCache: array,
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (row?.lineType == 1 || row?.isDelete == 0) {
          this.$message.error("引入源单的商品或已分货的不允许删除！");
          return;
        }
        // console.log('删除', index, row)
        if (this.distributeStoreForm.detailItem.length <= 1) {
          this.distributeStoreForm.detailItem = [{
            storeItem: [],
            storeItemCache: [],
            goodsNo: "",
            hoverRow: false,
          }]
        } else {
          this.distributeStoreForm.detailItem.splice(index, 1);
        }
      }
    },
    //引入源单
    getImportOrder() {
      if (!this.distributeStoreForm.produceDeptId)
        return this.$message.error("生产工厂不能为空");
      this.$router.push({
        name: "distributeImport",
        query: {
          produceDeptId: this.distributeStoreForm?.produceDeptId,
          type: "distributeImport",
        },
      });
    },
    //选中的商品
    getGoodS(rows) {
      this.loadingTable = true;
      if (rows.length <= 0) {
        this.distributeStoreForm.detailItem = getSelectAssign(
          rows,
          this.distributeStoreForm.detailItem
        );
        if (this.distributeStoreForm.detailItem.length <= 0) {
          this.distributeStoreForm.detailItem = [
            {
              // 仓库数组信息
              storeItem: [],
              storeItemCache: [],
              goodsNo: "",
              hoverRow: false,
            },
          ];
        }
        this.loadingTable = false;
        return;
      }
      //获取仓库名称
      let array = this.getOrderStoreArr();
      rows.forEach(async (v) => {
        array = JSON.parse(JSON.stringify(array));
        this.$set(v, "storeItem", array);
        this.$set(v, "storeItemCache", array);
        this.$set(v, "lineType", 2);
        this.$set(v, "storeQty", 0);
        this.$set(v, "unitSalePrice", v.salePrice);
        this.$set(v, "unitPurPrice", v.purPrice);
        v.hoverRow = false;
        v.remark = undefined;
      });

      this.distributeStoreForm.detailItem = getSelectAssign(
        rows,
        this.distributeStoreForm.detailItem
      );
      this.loadingTable = false;
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //配送路线
      const res = await queryDeliverLineList();
      this.deliverLineList = res.data;
      this.deliverLineList.unshift({
        lineId: -1,
        lineName: "未分配",
      });
      //获取单据日期
      this.distributeStoreForm.billDate = getNewDate();
      const res2 = await produceListAPI();
      //重复分货
      this.isDistribution = res2.data.system_BUSINESS_PRODUCE_CONFIG_15;
      //默认生产工厂
      const res3 = await produceDeptList({ produceDeptNo: "0000" });
      if (res3.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(
          this.distributeStoreForm,
          "produceDeptId",
          res3.rows[0]?.produceDeptId
        );
        //默认生产工厂名称
        this.$set(
          this.distributeStoreForm,
          "produceDeptName",
          res3.rows[0]?.produceDeptName
        );
        // this.changeProduceGroupChange(res3.rows[0]?.produceDeptId)
      }
      this.loading = false;
    },
    // 表单重置
    reset() {
      this.isImportSource = false; //是否引入源单
      this.distributeStoreForm = {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        detailItem: [
          {
            // 仓库数组信息
            storeItem: [],
            storeItemCache: [],
          },
        ],
      };
      this.resetForm("form");
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
