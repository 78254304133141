<template>
  <el-form ref="form" label-width="68px">
    <div class="wrap" id="wrap" v-loading="loading">
      <cardTitleCom cardTitle="查询" id="search-card" style="margin-bottom: 0">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch :filterTimeStr="thisMonth" v-model="queryParams" :getList="getList"
              @isShowHighCom="getIsShowHigh" :class="{ marB10: !showHigh }" dateTimeType='daterange' />
            <div v-show="showHigh" class="marT10 x-w">
              <el-form-item label="单据编号" prop="billNo">
                <el-input size="mini" class="inputWidth marR15" placeholder="全部" v-model="queryParams.billNo" />
              </el-form-item>
              <el-form-item label="单据状态" prop="billStatuss">
                <SelectLocal class="marR15" :multiple="true" v-model="queryParams.billStatuss" style="width: 250px"
                  :option="$select({ key: 'bill_status' }).option" />
              </el-form-item>
              <el-form-item label="生产工厂" prop="produceDeptIds">
                <SelectRemote :multiple="true" v-model="queryParams.produceDeptIds" style="width: 250px" :option="
                    $select({
                      key: 'listProduceDept'
                    }).option
                  " />
                <!-- buttons: [
                      { type: 'add', label: 'deptNo' },
                      { type: 'more', label: 'deptName' }
                    ], -->
              </el-form-item>
              <el-form-item label="生产组" prop="produceGroupIds">
                <SelectRemote :multiple="true" v-model="queryParams.produceGroupIds" style="width: 250px"
                  :option="$select({ key: 'listProduceGroup' }).option" />
              </el-form-item>
              <el-form-item label="生产班次" prop="produceOrderIds">
                <SelectRemote :multiple="true" v-model="queryParams.produceOrderIds" style="width: 250px"
                  :option="$select({ key: 'listProduceOrder' }).option" />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="单据信息" class="orderInfo" :hideCard="true">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isAuditBtn="true" :isExportBtn="true" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="auditBill('删除')" @handleAudit="auditBill('审核')"
              @handleRedoAudit="auditBill('反审核')" class="tableBtn" @handleExport="handleExport">
              <template #specialDiyBtn>
                <div class="x-f marR10" style="align-items: center">
                  <el-button class="guide" icon="iconfont icon-qizi" size="mini" @click="handleGuide">生成向导
                  </el-button>
                  <el-button class="pickMaterial" :class="{ opacityDisabled: singleMaterial }"
                    icon="iconfont icon-shengchanlingliao" size="mini" :disabled="singleMaterial"
                    @click="getLeadMaterial">领料
                  </el-button>
                  <el-button class="putStorage" :class="{ opacityDisabled: singleStore }" icon="iconfont icon-ruku1"
                    size="mini" :disabled="singleStore" @click="getInStoreDia">入库
                  </el-button>

                  <el-button class="finish" :class="{ opacityDisabled: multiple }"
                    icon="iconfont icon-wangongguanlibeifen2x" size="mini" :disabled="multiple" @click="getFinish">完工
                  </el-button>
                  <el-button class="distributeGoods" :class="{ opacityDisabled: singleSubdivision }"
                    icon="iconfont icon-assignPermissions-o" size="mini" :disabled="singleSubdivision"
                    @click="getStoreDistribute">分货
                  </el-button>
                  <el-button type="primary" size="mini" :disabled="ids.length <= 0" @click="getRelevantDocuments">相关单据
                  </el-button>
                  <PrintButton style="margin-left: 8px" :printTemplate="{
                      print: {},
                      label: '打印',
                      type: 'primary',
                      btnType: 'dropdown'
                    }" :check="selectionList" />
                </div>
              </template>
            </operatingButton>
            <el-table v-loading="loadingTable" ref="multipleTable" @row-click="handleRowClick" tooltip-effect="dark"
              :data="tableData" @selection-change="handleSelectionChange" border :max-height="tableHeight"
              :height="tableHeight">
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
              <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" min-width="160">
                <template slot-scope="scope">
                  <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                    {{ scope.row.billNo }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="手工单据号" align="center" prop="handBillNo" min-width="160" />
              <el-table-column label="单据状态" align="center" prop="billStatusName" min-width="120" />
              <el-table-column label="单据日期" align="center" prop="billDate" width="155" />
              <el-table-column label="生产工厂" align="center" prop="produceDeptName" width="130" />
              <el-table-column label="生产组" align="center" prop="produceGroupName" width="130" />
              <el-table-column label="生产班次" align="center" prop="produceOrderName" width="120" />

              <el-table-column label="领料状态" align="center" prop="leadMaterialStatusName" />

              <el-table-column label="入库状态" align="center" prop="inStoreStatusName" />
              <el-table-column label="完工状态" align="center" prop="finishWorkStatusName" />
              <el-table-column label="分货状态" align="center" prop="planDistributeStatusName" />
              <el-table-column label="生产日期" align="center" prop="produceDate" width="170" />
              <el-table-column label="备注" align="center" prop="billRemark" width="155" />
              <el-table-column label="创建人" align="center" prop="createBy" />
              <el-table-column label="创建时间" align="center" prop="createTime" width="155" />
              <el-table-column label="审核人" align="center" prop="auditBy" />
              <el-table-column label="审核时间" align="center" prop="auditTime" width="155" />
              <el-table-column label="修改人" align="center" prop="updateBy" />
              <el-table-column label="修改时间" align="center" prop="updateTime" width="155" />
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
              @selectAllCom="selectAll" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 产品入库 -->
      <c-dialog title="产品入库" :width="1210" :showDialog.sync="openInStoreGoodsList">
        <template #content>
          <div class="x-c-end">
            <el-button type="primary" size="mini" @click="getDistribution" class="marL10 marB10">批量修改仓库</el-button>
          </div>
          <el-form ref="formStore">
            <el-table v-loading="loadingInStoreDia" tooltip-effect="dark" ref="multipleTableInStore"
              :data="inStoreGoodsList" border @selection-change="handleSelectionChangeInStore"
              @row-click="handleRowClickInStore" max-height="500" height="500">
              <el-table-column type="selection" width="60" align="center" />
              <el-table-column label="序号" align="center" prop="userId" width="80" type="index" fixed="left" />
              <el-table-column show-overflow-tooltip label="商品编码" align="center" prop="goodsNo" min-width="180">
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName" min-width="155" />
              <el-table-column label="规格" align="center" prop="goodsSpec" />
              <el-table-column label="单位" align="center" prop="unitName" min-width="100" />
              <el-table-column label="生产计划数" align="center" prop="unitQty" min-width="100" />
              <el-table-column label="本次入库数" align="center" prop="nowInUnitQty" min-width="130">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.nowInUnitQty"></el-input>
                </template>
              </el-table-column>

              <el-table-column label="入库仓库" align="center" prop="inStoreId" min-width="180">
                <template slot-scope="scope">
                  <SelectRemote v-model="scope.row.inStoreId" style="width: 150px" :option="
                    $select({
                      key: 'listProduceStore',
                      option: {
                        option: {
                          remoteBody: {
                            produceDeptId: planForm.produceDeptId
                          },
                          showItem: [
                            {
                              storeId: scope.row.inStoreId,
                              produceStoreName: scope.row.inStoreName
                            }
                          ]
                        }
                      }
                    }).option
                  " />
                </template>
              </el-table-column>
              <el-table-column label="已入库数" align="center" prop="inUnitQty" min-width="100" />
            </el-table>
          </el-form>
        </template>
        <template #buttonList>
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="getInStore">确 定</el-button>
        </template>
      </c-dialog>
      <!-- 弹窗 -->
      <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import { inStoreAddAPI } from '@/api/produce/bill/inStore' //产品入库
import {
  planListAPI,
  planUpdateStatusAPI,
  finishWorkAPI,
  findDetailWithBomAPI,
  planDetailAPI,
  listImportBillInfo
} from '@/api/produce/bill/plan' //生产计划单
import { getNewDate } from '@/utils/newDate' //单据日期
import { getBillNo } from '@/api/codeRule' //单据号
import operatingButton from '@/views/components/operatingButton' //操作按钮
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import Dialog from '@/components/Dialog'
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { deepCopy } from '@/utils'
export default {
  name: 'Plan',
  dicts: ['bill_status'],
  components: {
    PrintButton: () => import('@/components/tablePage/tableButtons/print.vue'),
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectLocal,
    SelectRemote,
    operatingButton,
    Dialog,
    CDialog
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async vm => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == '/index') {
        vm.queryParams = {
          pageNum: 1,
          pageSize: 15,
          billStatuss: [to.query.billStatus]
        },
          vm.thisMonth = ''
        vm.getList()
      } else {
        vm.thisMonth = 'thisMonth'
      }
    })
  },
  data() {
    return {
      //入库勾选数组
      chooseOutStoreIds: [],
      //弹窗配置
      dialogOptions: {
        title: '选择仓库',
        width: 1250,
        show: false,
        type: 'TreeAndTable',
        formData: this.$dialog({ key: 'produceStore' })
      },
      thisMonth: '',
      selectionList: [], //勾选单据
      //入库商品数组
      inStoreGoodsList: [],
      //入库商品开关
      openInStoreGoodsList: false,
      loading: false, //最大遮罩
      loadingTable: false, //列表遮罩
      loadingInStoreDia: false, //入库遮罩
      multiple: true, // 非单个禁用
      singleStore: true, //入库按钮
      singleMaterial: true, //领料按钮
      singleSubdivision: true, //分货按钮
      showHigh: false, //是否显示高级搜索
      icon: 'el-icon-caret-bottom', //高级搜索图标
      name: [], //选中数组的名称
      ids: [], // 选中数组
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      billStatusFourId: [], //选中已审核未领料的单据状态id4
      billNo: undefined, //选中的第一个数组单据编号
      total: 0, // 总条数
      tableData: [], //表格数据
      planForm: {}, //订单详情
      datetime: undefined, //单据查询时间
      queryParams: {
        pageNum: 1,
        pageSize: 15
      }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
      chooseOutStoreArr: [],
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 84
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //相关单据
    async getRelevantDocuments() {
      if (this.ids.length != 1) {
        return this.$message.error('请勾选一条数据查询相关单据！')
      }
      const res = await listImportBillInfo(this.ids[0])
      let data = res.data || []
      this.$router.push({
        name: 'producePlanRelevant',
        query: {
          type: 'producePlan',
          data: JSON.stringify(data)
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      if (this.ids.length != 1) {
        this.$message.error('请勾选一条单据进行打印')
        return
      }
      this.download(
        '/api/system/produce/plan/exportGoodsInfo',
        {
          ...this.queryParams,
          billId: this.ids[0]
        },
        `生产计划单导出.xlsx`
      )
    },
    //仓库勾选当前行
    handleSelectionChangeInStore(selection) {
      this.chooseOutStoreArr = selection
      this.chooseOutStoreIds = this.chooseOutStoreArr
        .map(item => item.goodsId)
        .filter(ite => typeof ite !== 'undefined') //单据id
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case 'dialogChange':
          this.chooseOutStoreIdChange(
            this.dialogOptions.formData.table.check[0].storeId,
            this.dialogOptions.formData.table.check[0].produceStoreName
          )
          break
        default:
          break
      }
    },
    //批量修改仓库
    chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      try {
        this.loadingInStoreDia = true
        const promises = this.inStoreGoodsList.map(async item => {
          if (this.chooseOutStoreIds.includes(item.goodsId)) {
            this.$set(item, 'inStoreId', chooseOutStoreId)
            this.$set(item, 'inStoreName', produceStoreName)
          }
        })
        Promise.all(promises).then(() => {
          this.loadingInStoreDia = false
        })
      } catch (error) {
        this.loadingInStoreDia = false
      }
    },
    //打开弹窗
    getDistribution() {
      if (this.chooseOutStoreIds.length <= 0)
        return this.$message.warning('请先勾选商品')
      this.dialogOptions.title = '选择仓库'
      this.dialogOptions.formData = this.$dialog({
        key: 'produceStore',
        option: {
          table: {
            ...this.$dialog({ key: 'produceStore' }).table,
            radioSelect: true,
            mutiSelect: false
          }
        }
      })
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        produceDeptId: this.selectionList[0]?.produceDeptId
      }
      this.dialogOptions.show = true
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //分货
    async getStoreDistribute() {
      //跳转到分货详情
      this.$router.push({
        name: 'storeDistributeDetail',
        query: {
          sourceIds: JSON.stringify([
            {
              importBillId: this.ids[0],
              importType: 4
            }
          ]),
          produceDeptId: this.selectionList[0]?.produceDeptId,
          type: 'distributeImport'
        }
      })
    },
    //入库商品对话框确认按钮
    async getInStore() {
      if (this.chooseOutStoreArr.length <= 0) return this.$message.warning('请选择入库商品 !')
      let isBo = true
      for (let i = 0; i < this.chooseOutStoreArr.length; i++) {
        const item = this.chooseOutStoreArr[i];
        if (!item.inStoreId) {
          isBo = false
          break
        }
      }
      if (!isBo) {
        this.$message.warning('入库仓库不能为空 !')
        return
      }
      let chooseOutStoreArrNew = deepCopy(this.chooseOutStoreArr)
      chooseOutStoreArrNew = chooseOutStoreArrNew.map(item => {
        item.importUnitQty = item.unitQty
        item.unitQty = item.nowInUnitQty
        item.nowInUnitQty = item.inUnitQty
        return item
      })
      let obj = {
        details: chooseOutStoreArrNew,
        produceDeptId: this.planForm?.produceDeptId,
        deptId: this.planForm?.deptId,
        billDate: getNewDate(),
        billNo: await getBillNo(170203)
      }
      //调产品入库单新增接口
      await inStoreAddAPI(obj)
      // 入库商品开关
      this.openInStoreGoodsList = false
      //跳转产品入库单列表
      this.$router.push('/produce/bill/inStore')
      this.$message.success('入库成功 ！')
    },
    //关闭入库商品对话框的按钮
    close() {
      // 入库商品开关
      this.openInStoreGoodsList = false
    },
    //打开入库商品对话框的按钮
    async getInStoreDia() {
      this.chooseOutStoreIds = []
      this.chooseOutStoreArr = []
      // 入库商品开关
      this.openInStoreGoodsList = true
      //遮罩
      this.loadingInStoreDia = true
      //商品列表清空
      this.inStoreGoodsList = [{}]
      //调商品详情的接口
      const res = await planDetailAPI(this.billStatusStoreId[0])
      //赋值给订单详情对象
      this.planForm = res.data
      //赋值给入库商品对话框
      this.inStoreGoodsList = res.data.detailItem
      this.inStoreGoodsList.forEach(v => {
        v.nowInUnitQty = v.unitQty
        // v.billType = this.planForm.billType
        v.sourceBillDetailId = v.billDetailId
        v.billDetailId = null
        v.sourceBillId = v.billId
        v.billId = null

        v.sourceBillNo = v.billNo
        v.billNo = null

        v.sourceBillType = v.billType
        v.billType = null
      })
      //遮罩
      this.loadingInStoreDia = false
    },
    //领料
    async getLeadMaterial() {
      const { data } = await findDetailWithBomAPI(this.billStatusFourId[0])
      data.forEach(v => {
        this.$set(v, 'unitPrice', v.unitPurPrice)
        this.$set(v, 'billNo', this.billNo)
        this.$set(v, 'sourceBillId', this.billStatusFourId[0])
        this.$set(v, 'importUnitQty', v.unitQty)
      })
      this.$router.push({
        name: 'leadMaterialDetail',
        query: {
          sourceBillNo: this.billNo,
          produceDeptId: this.selectionList[0]?.produceDeptId,
          sourceGoodsList: JSON.stringify(data),
          type: 'importLeadMaterial'
        }
      })
    },
    //完工
    async getFinish() {
      await finishWorkAPI({ billIds: this.ids })
      this.$message({
        showClose: true,
        message: '更新完工状态成功',
        type: 'success'
      })
      await this.getList()
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //入库弹窗点击行选中
    handleRowClickInStore(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTableInStore.toggleRowSelection(row)
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      const { rows, total } = await planListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: '2'
          }
          //发送审核api
          await planUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.billStatusTwoId.length === 0) {
            this.$message.error('勾选单据中没有已审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusTwoId,
            billStatus: '0'
          }
          //发送反审核api
          await planUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('反审核成功')
        } else if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: '3'
          }
          //发送删除api
          await planUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        await this.getList()
      } catch { }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectionList = selection //单据
      this.ids = selection.map(item => item.billId) //单据id
      if (selection.length > 0) {
        this.billNo = selection[0].billNo //单据billNo
      }
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map(item => {
          if (item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if (item.billStatus != '3' && item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')

      //筛选出来已审核未领料的单据状态id4
      this.billStatusFourId = selection
        .map(item => {
          if (
            item.billStatus == '2' &&
            item.leadMaterialStatusName != '已领料'
          ) {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核未完全入库的单据状态id4
      this.billStatusStoreId = selection
        .map(item => {
          if (item.billStatus == '2' && item.inStoreStatusName != '完全入库') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')

      //筛选出来已审核未分货的单据状态id4
      this.billStatusSubdivisionId = selection
        .map(item => {
          if (
            item.billStatus == '2' &&
            item.planDistributeStatusName != '已分货'
          ) {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
      // 分货
      if (selection.length == 1 && this.billStatusSubdivisionId.length == 1) {
        this.singleSubdivision = false
      } else {
        this.singleSubdivision = true
      }
      // 入库
      if (selection.length == 1 && this.billStatusStoreId.length == 1) {
        this.singleStore = false
      } else {
        this.singleStore = true
      }

      /* 领料按钮 */
      if (selection.length == 1 && this.billStatusFourId.length == 1) {
        this.singleMaterial = false
      } else {
        this.singleMaterial = true
      }
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await planListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },

    //向导生成
    handleGuide() {
      this.$router.push({
        name: 'guideDetail'
      })
    },
    //新增单据事件
    handleAdd() {
      const timestampId = Date.now()
      //跳转商品明细页
      this.$router.push({
        path: '/produce/bill/plan/planAdd',
        query: {
          type: 'add',
          timestampId
        }
      })
    },
    //跳转详情
    handleDetail(row) {
      const billId = row.billId
      this.$router.push({
        path: '/produce/bill/plan/planDetail',
        query: {
          billId: billId,
          type: 'update'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
    .tableBtn {
      //向导
      .guide {
        color: #ffffff;
        background-color: #9449f7;
      }
      ::v-deep .iconfont {
        font-size: 12px;
      }
      //入库
      .putStorage {
        color: #ffffff;
        background-color: #0dc46a;
      }
      //领料
      .pickMaterial {
        color: #ffffff;
        background-color: #f2880c;
      }
      //完工
      .finish {
        color: #ffffff;
        background-color: #6261ea;
      }
      //分货
      .distributeGoods {
        color: #ffffff;
        background-color: #7fb708;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 6px;
}
</style>
